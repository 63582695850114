<template>
	<div id="wrap">
		<div class="popup active">
			<div class="notice_popup field">
				<div class="close">
					<img src="/images/close.png" alt="닫기" @click="$emit('close', false)"/>
				</div>
				
				<div v-if="gubun == '1'" class="tit">모집마감 해제</div>
				<div v-else-if="gubun == '2'" class="tit">모집마감으로 변경</div>

				<div class="field">모집분야 <span>'{{workCont}}'</span></div>
				<div class="period">〈 모집기간 : <span>{{projectRecrStartEndYyyymmdd}}</span> 〉</div>

				<div v-if="gubun == '1'" class="member">위 모집분야의 상태를 ‘모집마감 해제’로 변경하시겠습니까?</div>
				<div v-else-if="gubun == '2'" class="member">위 모집분야의 상태를 ‘모집마감’으로 변경하시겠습니까?</div>

				<div class="select-btn">
					<div class="btn" @click="$emit('close', false)">취소</div>
					<div class="btn" @click="save()">예</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

export default {

	components : {		
	},

	props: ['param'],

	data() {
		return {
			projectRecrFieldSeq : this.param.projectRecrFieldSeq,			
			workCont : this.param.workCont,
			projectRecrStartEndYyyymmdd : this.param.projectRecrStartEndYyyymmdd,
			
			gubun : this.param.gubun,		// 1:모집마감 상태 (모집마감 해제 처리), 2:모집중상태 (모집마감 등록 처리)

		};
	},
	beforeCreate() {
		// console.log('beforeCreate');
	},
	created() {
		// console.log('created');
	},
	beforeMount() {
		// console.log('beforeMount');		
	},
	mounted() {
		// console.log('mounted');	
	},
	beforeUpdate() {
		// console.log('beforeUpdate');
	},
	updated() {
		// console.log('update');
	},
	beforeDestroy() {
		// console.log('beforeDestroy');
	},
	destroyed() {
		// console.log('destroyed');
	},

	methods: {

		changeProjectRecrField(event, val, item){
            // console.log('changeProjectRecrField', event, val, item);
            this.projectRecrField = item || {};
        },

		save() {
			
			var input = {
				projectRecrFieldSeq : this.projectRecrFieldSeq,
				recrEndYn : ''
			}

			// gubun - 1:모집마감 상태 (모집마감 해제 처리), 2:모집중상태 (모집마감 등록 처리)
			if(this.gubun == '1') {
				input.recrEndYn = null;
			} else if(this.gubun == '2') {
				input.recrEndYn = 'Y';
			}

			this.$.httpPost('/api/prj/adm/saveRecrEndYn', input)
				.then(() => {
					this.$emit('close', true);
				})
				.catch(err => {
					// console.log('============================='); 
					// console.log(err);
					// console.log(err.response);
					// console.log('============================='); 

					alert(err.response.data.error_description);
				});						

		}
	},
};
</script>
